




































































































import { AddAdminApi } from "@/includes/Api/AddAdmin.api";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { AddAdminByLinkRequest, AddAdminByLinkResponse, FullAddAdminRequest } from "@/includes/types/AddAdmin/types";
import { InputSetups } from "@/mixins/input-setups";

import { copyTokenToClipboard } from "piramis-base-components/src/logic/helpers/copy-to-clipboard";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';

import { Component, VModel, Mixins, PropSync, Emit } from 'vue-property-decorator';

enum RequestType {
  Link,
  Name
}

@Component({
  data() {
    return {
      RequestType
    }
  },
  components: {
    TextInput
  }
})
export default class AddAdminModal extends Mixins(InputSetups) {
  @VModel() modal!: boolean

  @PropSync('model') requestModel!: any

  deadline = () => new Date().getTime() + 600000

  currentAddType: RequestType = RequestType.Name

  checkAddStatusTimeoutId: number | null = null

  tokenShowTo: string | null = null

  confirmLoading = false

  @Emit()
  resetModel() {
    return null
  }

  @Emit()
  updateUsers(users: Record<number, string>) {
    return users
  }

  resetStateToDefault(): void {
    this.modal = false
    this.currentAddType = RequestType.Name
    this.resetModel()
    this.tokenShowTo = null

    if (this.checkAddStatusTimeoutId) {
      clearTimeout(this.checkAddStatusTimeoutId)
    }
  }

  async checkAddStatus(token: AddAdminByLinkResponse['token']) {
    const { data } = await AddAdminApi.stateAdminByCode('tg', { token })

    if (data.binded && data.users_map && this.checkAddStatusTimeoutId) {
      this.tokenShowTo = null
      clearTimeout(this.checkAddStatusTimeoutId)

      this.updateUsers(data?.users_map ?? {})
    } else {
      this.checkAddStatusTimeoutId = setTimeout(async () => {
        await this.checkAddStatus(token)
      }, 3500)
    }
  }

  setAddAdminType(type: RequestType): void {
    this.currentAddType = type

    if (type === RequestType.Link) {
      this.$delete(this.requestModel, 'username')
    }
    if (type === RequestType.Name) {
      this.$set(this.requestModel, 'username', '')
    }
  }

  handleAdminByLinkResponse(response: AddAdminByLinkResponse): void {
    const { token, link } = response

    this.tokenShowTo = link

    this.checkAddStatus(token)
  }

  copyToken(): void {
    copyTokenToClipboard(this.tokenShowTo)
    successNotification(this.$t('copy_link_to_buffer').toString())
  }

  isFullAddAdminStructure(model: FullAddAdminRequest | AddAdminByLinkRequest): model is FullAddAdminRequest {
    return 'username' in model
  }

  makeRequest(): void {
    if (this.currentAddType === RequestType.Name && this.isFullAddAdminStructure(this.requestModel)) {
      this.confirmLoading = true

      AddAdminApi.addAdminByName('tg', this.requestModel)
        .then(({ data }) => this.updateUsers(data?.users_map ?? {}))
        .catch(errorNotification)
        .finally(() => this.confirmLoading = false)
    }

    if (this.currentAddType === RequestType.Link) {
      this.confirmLoading = true

      AddAdminApi.addAdminByLink('tg', this.requestModel)
        .then(({ data }) => this.handleAdminByLinkResponse(data))
        .catch(errorNotification)
        .finally(() => this.confirmLoading = false)
    }
  }
}
