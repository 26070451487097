import { UsersModelPath } from "@/components/AddAdmin/types";
import { AddAdminType } from "@/includes/types/AddAdmin/Enums";
import { errorNotification, successNotification } from "@/includes/NotificationService";
import { AddAdminApi } from "@/includes/Api/AddAdmin.api";
import { AddAdminByLinkRequest, FullAddAdminRequest, UsersResultResponse } from "@/includes/types/AddAdmin/types";

import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { cloneDeep } from "lodash";

@Component
export default class AddAdminsMixin extends Vue {
  @Prop() moduleGuid!: string

  @Prop({ type: Boolean, default: false }) addUsersDisabled!: boolean

  @Prop({ type: Object, required: true }) usersPath!: UsersModelPath

  @Prop({ type: String, required: true }) addType!: AddAdminType

  modal = false

  requestModel: FullAddAdminRequest | AddAdminByLinkRequest = this.defaultModel()

  defaultModel(): FullAddAdminRequest {
    return cloneDeep({
      chat_id: this.$store.state.chatState.chat.chat_id,
      type: this.addType,
      username: '',
      ...this.moduleGuid ? {
        module: this.moduleGuid
      } : {}
    })
  }

  handleUsers(users: UsersResultResponse['users_map']): void {
    this.updateConfigModel(users!, this.usersPath)
        .then(() => {
          this.modal = false
          successNotification()
        })
  }

  onRemoveClick(username: string): void {
    const req = Object.assign({}, this.requestModel, { username })

    AddAdminApi.deleteAdmin('tg', req)
        .then(({ data }) => this.handleUsers(data.users_map))
        .catch(errorNotification)
  }

  async updateConfigModel(newUsers: Record<number, string>, modelPath: UsersModelPath): Promise<void> {
    if (modelPath && modelPath.key && modelPath.model) {
      const { model, key } = modelPath

      this.$store.commit('pi/EXEC', {
        'key': key,
        'fn': () => {
          this.$set(model, key, newUsers)
        },
      })

      return Promise.resolve()
    }
  }

}
