




































import { UsersModelPath } from '../types'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { get } from 'lodash'
import { SelectOption } from 'piramis-base-components/src/logic/types'

@Component({})
export default class AddAdminList extends Vue {
  @Prop() usersPath!:UsersModelPath

  @Prop({ type: Boolean, default: false }) disabled!: boolean

  @Emit()
  onAdminRemove(username: string):string {
    return username
  }

  @Emit()
  openModal() {}

  handleCloseClick(username:SelectOption):void {
    this.$confirm({
      title: this.$t("remove_user_confirm_title").toString(),
      content: this.$t("remove_user_confirm_text", [ username.label ]).toString(),
      okText: this.$t('pi_accept').toString(),
      cancelText: this.$t('pi_reject').toString(),
      onOk: () => {
        this.onAdminRemove(username.value)
      }
    })
  }

  get usersList():Array<SelectOption> {
    const model = get(this.usersPath.model, this.usersPath.key)

    if (Array.isArray(model)) {
      return model.map(login => ({
        label: login,
        value: login,
      }))
    }

    if (typeof model === 'object') {
      const entries = Object.entries(model)

      if (entries.length) {
        return entries
          .slice()
          .map(([ key, value ]) => ({
            value: key,
            label: value
          }))
      }

      return []
    }

    throw Error(`unknown modelPath type, got: ${ typeof model }`)
  }
}
