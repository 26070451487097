














import BaseModule from '../../../includes/logic/Modules/models/BaseModule'
import { InputSetups } from '@/mixins/input-setups'

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ModuleManagerState } from '@/includes/logic/Modules/ModulesManager'

@Component({
  components: {
    SwitchInput
  }
})
export default class ModuleEnableSwitch extends Mixins<InputSetups>(InputSetups) {
  @VModel() module!: BaseModule

  set isEnabled(value: boolean) {
    this.module.config.enabled = value
  }

  get isEnabled() {
    return this.module.config.enabled
  }

  mounted() {
    if (!ModuleManagerState?.isCurrentModuleSaved) {
      this.isEnabled = false
    }
  }
}
