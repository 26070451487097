



























import AddAdminModal from "@/components/AddAdmin/components/AddAdminModal.vue";
import AddAdminList from './components/AddAdminList.vue'
import AddAdminsMixin from "@/components/AddAdmin/AddAdminsMixin";

import MiniHelpMessage from "piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    MiniHelpMessage,
    AddAdminModal,
    AddAdminList
  }
})
export default class AddAdmin extends Mixins(AddAdminsMixin) {
}
