export enum AddAdminType {
  Site = 'Site',
  Report = 'Report',
  Enter = 'Enter',
  Reputation = 'Reputation',
  FiltersIgnored = 'FiltersIgnored',
  Chat = 'Chat',
  DailyReport = 'DailyReport',
  StatisticsIgnored = 'StatisticsIgnored'
}
